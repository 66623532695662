export const environment = {
  production: false,
  baseUrl: "https://api.dev.aritzia.com",
  azure: {
    redirectUri: 'https://client-lookup-dev.aritzia.com',
  },
  idle: {
    inactive: 540,
    timeout: 60,
  },
  cdc: {
    servicePath: '/external/cdc',
    uris: {
      accountSearch: 'accounts.search',
      accountGet: 'accounts.getAccountInfo',
      accountSet: 'accounts.setAccountInfo',
    },
  },
  apigee: {
    host: "https://api.dev.aritzia.com",
    tokenPath: "/oauth2/token",
    banner: "Aritzia_CA"
  },
  msGraphAPI:{
    host: "https://graph.microsoft.com",
    path: "/v1.0/users"
  }
};